import useProductStore from '@/store/product'
import { ProductShipping } from '@/types'
import {
  OPTION_TYPE_SHIPPED_FROM,
  PRODUCT_TAG_SHIP_FROM_US,
  QUERY_STRING_NO_CACHE,
  US_COUNTRY_CODE,
  debounce,
  PRODUCT_TAG_EXPRESS_SHIPPING_PREFIX,
  PRODUCT_TAG_EXPRESS_SHIPPING_CHINA,
  PRODUCT_HANDLE_CAMP_WIN,
} from '@/utils'
import { Ref, computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import LocationService, { UserLocation } from '@/services/location'
import useSettingStore from '@/store/setting'
import { isProductHasTags, isProductHasTagStartWith } from '@/utils/product'
import useRequestLocationStore from '@/store/requestLocation'

export function useProductShipping() {
  const route = useRoute()
  const productStore = useProductStore()
  const settingStore = useSettingStore()
  const requestLocation = useRequestLocationStore()

  const isShippedFromUS = computed(
    () => productStore.productShipping?.ship_from === US_COUNTRY_CODE
  )

  const isExpressShippingChina = computed(() => {
    return (
      isProductHasTagStartWith(
        productStore.product,
        PRODUCT_TAG_EXPRESS_SHIPPING_PREFIX
      ) && hasExpressShippingChinaProductTag.value
    )
  })

  const hasExpressShippingChinaProductTag = computed(() => {
    return isProductHasTags(productStore.product, [
      PRODUCT_TAG_EXPRESS_SHIPPING_CHINA,
    ])
  })

  const isProductShippingLoaded = computed(() => {
    return !!productStore.productShipping
  })

  const isEnableShippedFromOption = computed(() => {
    const productHandles =
      settingStore.pages?.productPage?.productHandlesEnableShippedFromOption ||
      []

    return (
      productStore.product &&
      (isExpressShippingChina.value ||
        isProductHasTags(productStore.product, [PRODUCT_TAG_SHIP_FROM_US]) ||
        productHandles.includes(productStore.product.handle))
    )
  })

  const productShippingMap: Ref<{
    [key: string]: ProductShipping
  }> = ref({})

  const SHIP_FROM_OVERSEAS = 'Overseas'
  const SHIP_FROM_US = 'US'

  const fixedShippingFromChina: ProductShipping = {
    maximum_shipping: 19,
    minimum_shipping: 8,
    processing: 3,
    ship_from: SHIP_FROM_OVERSEAS,
  }

  const fixedShippingFromUS: ProductShipping = {
    maximum_shipping: 7,
    minimum_shipping: 2,
    processing: 3,
    ship_from: SHIP_FROM_US,
  }

  // fetch shipping by product sku prefix (new logic)
  async function fetchProductShippingBySkuPrefix() {
    const countryCode = requestLocation.countryCode || ''

    if (!productStore.product) return

    const productSkuPrefix = productStore.selectedVariant?.skuPrefix || ''
    const productFullSku = productStore.selectedVariant?.fullSku || ''
    const productVendor = productStore.product?.vendor || ''

    if (
      productShippingMap.value[
        buildProductShippingKeyBySkuPrefix({
          productSkuPrefix,
          productFullSku,
          productVendor,
          countryCode,
        })
      ]
    ) {
      productStore.productShipping =
        productShippingMap.value[
          buildProductShippingKeyBySkuPrefix({
            productSkuPrefix,
            productFullSku,
            productVendor,
            countryCode,
          })
        ]
    } else {
      if (!productSkuPrefix) return
      const productShipping =
        await productStore.fetchProductShippingBySkuPrefix({
          sku_prefix: productSkuPrefix,
          full_sku: productFullSku,
          vendor: productVendor,
          delivery_country_code: countryCode,
          isForceLoadNewData: !!route.query[QUERY_STRING_NO_CACHE],
        })
      if (productShipping) {
        productShippingMap.value[
          buildProductShippingKeyBySkuPrefix({
            productSkuPrefix,
            productFullSku,
            productVendor,
            countryCode,
          })
        ] = productShipping
      }
    }

    // handle shipping 'produced and shipping from' Overseas or US
    if (!isEnableShippedFromOption.value || !productStore.selectedVariant)
      return
    let productOtherSkuPrefix = ''
    let productOtherShiping

    const selectedShippedOption =
      productStore.selectedVariant.selectedOptions?.find(
        (selectedOption) => selectedOption.name === OPTION_TYPE_SHIPPED_FROM
      )
    if (selectedShippedOption?.value === SHIP_FROM_OVERSEAS) {
      productOtherSkuPrefix = getProductOtherSkuPrefix(
        productSkuPrefix,
        SHIP_FROM_US
      )
    }
    if (selectedShippedOption?.value === SHIP_FROM_US) {
      productOtherSkuPrefix = getProductOtherSkuPrefix(
        productSkuPrefix,
        SHIP_FROM_OVERSEAS
      )
    }

    if (productOtherSkuPrefix) {
      if (
        productShippingMap.value[
          buildProductShippingKeyBySkuPrefix({
            productSkuPrefix: productOtherSkuPrefix,
            productFullSku,
            productVendor,
            countryCode,
          })
        ]
      ) {
        productOtherShiping =
          productShippingMap.value[
            buildProductShippingKeyBySkuPrefix({
              productSkuPrefix: productOtherSkuPrefix,
              productFullSku,
              productVendor,
              countryCode,
            })
          ]
      } else {
        productOtherShiping = await productStore.getProductShippingBySkuPrefix({
          sku_prefix: productOtherSkuPrefix,
          full_sku: productFullSku,
          vendor: productVendor,
          delivery_country_code: countryCode,
          isForceLoadNewData: !!route.query[QUERY_STRING_NO_CACHE],
        })
        if (productOtherShiping) {
          productShippingMap.value[
            buildProductShippingKeyBySkuPrefix({
              productSkuPrefix: productOtherSkuPrefix,
              productFullSku,
              productVendor,
              countryCode,
            })
          ] = productOtherShiping
        }
      }
    }

    if (selectedShippedOption?.value.includes(SHIP_FROM_OVERSEAS)) {
      productStore.shippingFromChina =
        productStore.productShipping as ProductShipping
      productStore.shippingFromUS = productOtherShiping
    }
    if (selectedShippedOption?.value === SHIP_FROM_US) {
      productStore.shippingFromUS =
        productStore.productShipping as ProductShipping
      productStore.shippingFromChina = productOtherShiping
    }
  }

  function getProductOtherSkuPrefix(skuPrefix: string, type: string) {
    const variantFiltered = productStore.product?.variants?.filter(
      (variant) => {
        const hasSelectedOptionSameType = variant.selectedOptions?.find(
          (option) =>
            option.name == OPTION_TYPE_SHIPPED_FROM && option.value == type
        )
        return hasSelectedOptionSameType || ''
      }
    )
    if (!variantFiltered?.length) return ''

    // start logic apply for camp mth7138
    let otherSkuPrefixCampWin = ''
    if (productStore.product?.handle == PRODUCT_HANDLE_CAMP_WIN) {
      if (type.includes(SHIP_FROM_OVERSEAS)) {
        if (skuPrefix.includes('HSV')) otherSkuPrefixCampWin = 'AHD'
        if (skuPrefix.includes('CSV')) otherSkuPrefixCampWin = 'ALS'
      }
      if (type == SHIP_FROM_US) {
        if (skuPrefix.includes('AHD')) otherSkuPrefixCampWin = 'HSV'
        if (skuPrefix.includes('ALS')) otherSkuPrefixCampWin = 'CSV'
      }

      const otherSkuPrefix = variantFiltered?.find(
        (variant) => variant.skuPrefix?.slice(0, 3) == otherSkuPrefixCampWin
      )
      if (!otherSkuPrefix) return ''
      return otherSkuPrefix.skuPrefix || ''

      // end logic apply for camp mth7138
    } else {
      const otherSkuPrefix = variantFiltered?.find(
        (variant) => variant.skuPrefix?.slice(0, 3) == skuPrefix.slice(0, 3)
      )
      if (!otherSkuPrefix) return ''
      return otherSkuPrefix.skuPrefix || ''
    }
  }

  function initiateProductShipping() {
    const fetchShippingDebounce = debounce(async () => {
      return await fetchProductShippingBySkuPrefix()
    }, 500)

    watch(
      () => productStore.selectedVariant,
      async (newValue, oldValue) => {
        if (newValue && newValue != oldValue) {
          await fetchShippingDebounce()
        }
      },
      { immediate: true }
    )
  }

  function buildProductShippingKey({
    productType,
    countryCode,
  }: {
    productType: string
    countryCode?: string
  }): string {
    return `${productType || ''}-${countryCode || ''}`
  }

  function buildProductShippingKeyBySkuPrefix({
    productSkuPrefix,
    productSkuFull,
    productVendor,
    countryCode,
  }: {
    productSkuPrefix: string
    productSkuFull: string
    productVendor: string
    countryCode?: string
  }): string {
    return `${productSkuPrefix || ''}-${productSkuFull || ''}-${
      productVendor || ''
    }-${countryCode || ''}`
  }

  return {
    initiateProductShipping,
    isShippedFromUS,
    isProductShippingLoaded,
    isEnableShippedFromOption,
    fixedShippingFromChina,
    fixedShippingFromUS,
  }
}
