<template>
  <div class="container-featured-collection">
    <h2 v-if="showHeader" class="text--center mb-lg">Featured Products</h2>
    <div class="featured-collection">
      <sib-link
        @click="$emit('item-click')"
        :to="collection.url"
        :title="collection.title"
        class="featured-collection__item"
        v-for="(collection, index) of transformedProps"
        :key="index"
      >
        <div class="image-container">
          <sib-image v-bind="collection"></sib-image>
        </div>
        <h3 v-if="showHeader">{{ collection.title }}</h3>
      </sib-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity'

interface FeaturedCollection {
  image: string
  title: string
  url: string
}

const props = withDefaults(
  defineProps<{
    items: Array<FeaturedCollection>
    showHeader?: boolean
  }>(),
  {
    items: () => [],
    showHeader: true,
  }
)

const transformedProps = computed(() => {
  return props.items.map((item) => {
    return {
      width: 640,
      height: 640,
      src: item.image,
      alt: item.title,
      title: item.title,
      url: item.url,
      loading: 'lazy',
      sources: [
        {
          size: 160,
        },
        {
          size: 270,
          media: 361,
        },
        {
          size: 360,
          media: 580,
        },
        {
          size: 220,
          media: 768,
        },
        {
          size: 240,
          media: 992,
        },
        {
          size: 270,
          media: 1200,
        },
        {
          size: 400,
          media: 1400,
        },
      ],
      generateSrcSet: true,
    }
  })
})
</script>

<style lang="scss">
.featured-collection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;

  &__item {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 1em;
    text-align: center;
  }

  .image-container img {
    object-fit: cover;
  }

  @include media-md-up() {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
