import Http from '@/services/http'
import {
  SEARCHANISE_IMAGE_SIZE_VARIABLE,
  SEARCHANISE_TAG_SPLITER,
  SEARCH_RESTRICT_BY_PARAM_PREFIX,
  SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX,
} from '@/utils'
import { buildQueryString } from '@/utils/http'
import {
  Product,
  Collection,
  SearchProductParams,
  SearchResults,
} from '@/types'
import {
  SearchaniseProductParams,
  SearchaniseSearchResult,
} from '@/provider/type'
import { mappingSearchaniseVariantToProductVariant } from '@/utils/product'
import { Search } from './search'

export class Seachanise implements Search {
  async searchProduct(payload: SearchProductParams): Promise<SearchResults> {
    const params: SearchaniseProductParams = {
      q: payload.query,
      categories: true,
      pages: true,
      suggestions: true,
      output: 'json',
      sortBy: payload.sortBy || '',
      sortOrder: payload.sortOrder || '',
      'restrictBy[tags]': payload.restrictByTags || '',
    }

    const url = `https://searchserverapi.com/getwidgets?api_key=${
      import.meta.env.VITE_SEARCHANISE_API_KEY
    }&${buildQueryString(params)}`
    const response: SearchaniseSearchResult = await Http.get(url)
    const result: SearchResults = {
      collections: response.categories?.map((category) => {
        return {
          handle: category.link,
          title: category.title,
        } as Collection
      }),
      products: response.items?.map((item) => {
        return {
          handle: item.link,
          images: item.shopify_images?.map((image) => {
            return {
              src: image.replace(SEARCHANISE_IMAGE_SIZE_VARIABLE, ''),
              alt: item.title,
            }
          }),
          title: item.title,
          productType: item.product_type,
          tags: item.tags?.split(SEARCHANISE_TAG_SPLITER),
          price: +item.price,
          variants: item.shopify_variants?.map(
            mappingSearchaniseVariantToProductVariant
          ),
        } as Product
      }),
      pages: response.pages?.map((page) => {
        return {
          title: page.title,
          handle: page.link,
        }
      }),
      suggestions: response.suggestions,
      totalProducts: +(response.totalItems || 0),
      totalCollections: +(response.totalCategories || 0),
      totalPages: +(response.totalPages || 0),
    }

    return result
  }

  async searchProductFull(
    payload: SearchProductParams
  ): Promise<SearchResults> {
    const params: SearchaniseProductParams = {
      q: payload.query || '',
      categories: false,
      pages: false,
      suggestions: true,
      output: 'json',
      facets: true,
      facetsShowUnavailableOptions: false,
      sortBy: payload.sortBy || '',
      sortOrder: payload.sortOrder || '',
      maxResults: payload.itemsPerPage || 20,
      startIndex: payload.startIndex || 0,
      collection: payload.collection || '',
    }

    for (const key in payload) {
      if (!key.startsWith(SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX)) continue
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        const values = payload[key]
        const keyWithoutPrefix = key
          .split(SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX)
          .pop()

        const paramKey = `${SEARCH_RESTRICT_BY_PARAM_PREFIX}[${keyWithoutPrefix}]`

        if (Array.isArray(values)) {
          const searchANDNotValue = values.filter((value) =>
            value.startsWith('!')
          )
          const searchORValue = values.filter((value) => !value.startsWith('!'))
          if (searchANDNotValue.length) {
            params[paramKey] = `${searchANDNotValue.join(',')}${
              searchORValue.length ? ',' : ''
            }${searchORValue.join('|')}`
          } else {
            params[paramKey] = values.join('|')
          }
        } else {
          params[paramKey] = payload[key]
        }
      }
    }

    const url = `https://searchserverapi.com/getresults?api_key=${
      import.meta.env.VITE_SEARCHANISE_API_KEY
    }&${buildQueryString(params)}`
    const response: SearchaniseSearchResult = await Http.get(url)
    const result: SearchResults = {
      collections: response.categories?.map((category) => {
        return {
          handle: category.link,
          title: category.title,
        } as Collection
      }),
      products: response.items?.map((item) => {
        return {
          id: item.product_id,
          handle: item.link,
          images: item.shopify_images?.map((image) => {
            return {
              src: image.replace(SEARCHANISE_IMAGE_SIZE_VARIABLE, ''),
              alt: item.title,
            }
          }),
          title: item.title,
          productType: item.product_type,
          tags: item.tags?.split(SEARCHANISE_TAG_SPLITER),
          price: +item.price,
          variants: item.shopify_variants?.map(
            mappingSearchaniseVariantToProductVariant
          ),
        } as Product
      }),
      pages: response.pages?.map((page) => {
        return {
          title: page.title,
          handle: page.link,
        }
      }),
      suggestions: response.suggestions,
      totalProducts: +(response.totalItems || 0),
      totalCollections: +(response.totalCategories || 0),
      totalPages: +(response.totalPages || 0),
      facets: response.facets,
    }

    return result
  }

  async searchProductByCollection(
    payload: SearchProductParams
  ): Promise<SearchResults> {
    return this.searchProductFull(payload)
  }
}
