import Analytics from '@/services/analytics'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import { CookieManager } from '@/services/cookie'
import Logger from '@/services/log'
import useSettingStore from '@/store/setting'
import useProductStore from '@/store/product'
import { extractProductSKUFromHandle } from '@/utils/product'
import { NullOrType, Product } from '@/types'
import {
  areArraysEqual,
  CACHE_KEY_RECENTLY_VIEWED_ITEMS,
  CACHE_KEY_RECENTLY_VIEWED_ITEMS_SKU_FROM_HANDLE,
  cloneDeep,
  THIRTY_DAYS_IN_MILLISECONDS,
  THIRTY_DAYS_IN_SECONDS,
} from '@/utils'
import { computed } from 'vue'

export const useProductRecentlyViewedItems = () => {
  const recentlyViewedItemsMaxAge = THIRTY_DAYS_IN_MILLISECONDS
  const settingStore = useSettingStore()
  const productStore = useProductStore()
  const maxNumberOfItems = computed(
    () => settingStore.pages?.productPage?.recentlyViewedProducts || 10
  )
  // save the current product to the cache
  async function saveProductToCache(
    product: NullOrType<Product>,
    isReplaceData = false
  ) {
    try {
      if (!product) return
      const recentlyViewedItems = await synchronizeRecentlyViewedItems()
      // find the position of the product on the list
      const index = recentlyViewedItems.findIndex(
        (item) => item.handle === product.handle
      )
      if (isReplaceData) {
        // replace the product if it is already on the list
        if (index > -1) recentlyViewedItems.splice(index, 1, product)
      } else {
        // remove the product if it is already on the list
        if (index > -1) recentlyViewedItems.splice(index, 1)
        // add the product to the head of the list
        recentlyViewedItems.unshift(product)
      }

      // limit the list to the max number of items
      recentlyViewedItems.splice(
        maxNumberOfItems.value,
        recentlyViewedItems.length - maxNumberOfItems.value
      )
      // save the list to the cache IndexDB
      await CacheService.instance?.set(
        CACHE_KEY_RECENTLY_VIEWED_ITEMS,
        cloneDeep(recentlyViewedItems), // need to use cloneDeep here because indexedDB can't store proxy
        recentlyViewedItemsMaxAge
      )

      // save the list to the cache cookie to synchronize for online store and shopify theme
      const cookier = new CookieManager()
      const href = new URL(window.location.href)
      const currentDomain = href.hostname
      const recentlyViewedItemsSKUFromHandle = cloneDeep(recentlyViewedItems)
        .map((product) => extractProductSKUFromHandle(product.handle))
        .join(',')

      cookier.setCookie(
        CACHE_KEY_RECENTLY_VIEWED_ITEMS_SKU_FROM_HANDLE,
        recentlyViewedItemsSKUFromHandle,
        {
          maxage: THIRTY_DAYS_IN_SECONDS,
          domain: '.' + currentDomain,
          path: '/',
        }
      )
    } catch (error: any) {
      Logger.error('Error when saving product to cache', {
        error,
        product,
      })
      Analytics.error(error)
    }
  }

  // get the recently viewed products from the cache
  async function getRecentlyViewedItems() {
    const recentlyViewedItemsFromCache = await CacheService.instance?.get(
      CACHE_KEY_RECENTLY_VIEWED_ITEMS
    )
    const recentlyViewedItems: Product[] =
      getJSONfromCacheResponse(recentlyViewedItemsFromCache) || []

    return recentlyViewedItems
  }

  async function synchronizeRecentlyViewedItems() {
    const recentlyViewedItems: Product[] = await getRecentlyViewedItems()

    const cookier = new CookieManager()
    const recentlyViewedItemsSKUFromHandleCookie =
      cookier
        .getCookie(CACHE_KEY_RECENTLY_VIEWED_ITEMS_SKU_FROM_HANDLE)
        ?.split(',') || []

    if (recentlyViewedItemsSKUFromHandleCookie.length) {
      const recentlyViewedItemsSKUFromHandle = recentlyViewedItems.map(
        (product) => extractProductSKUFromHandle(product.handle)
      )

      // Check to sync recently viewed product data
      const shouldSynchronizeRecentlyViewedItems = !areArraysEqual(
        recentlyViewedItemsSKUFromHandle,
        recentlyViewedItemsSKUFromHandleCookie
      )

      if (shouldSynchronizeRecentlyViewedItems) {
        const recentlyViewedItemsNewest =
          await productStore.getProductRecentlyViewed({
            recently_view: recentlyViewedItemsSKUFromHandleCookie,
          })

        if (!recentlyViewedItemsNewest?.length) return recentlyViewedItems

        await CacheService.instance?.set(
          CACHE_KEY_RECENTLY_VIEWED_ITEMS,
          cloneDeep(recentlyViewedItemsNewest),
          recentlyViewedItemsMaxAge
        )

        return recentlyViewedItemsNewest
      }
    }

    return recentlyViewedItems
  }

  return {
    saveProductToCache,
    getRecentlyViewedItems,
  }
}
