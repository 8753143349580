<template>
  <div
    class="product-block"
    :class="{ 'product-block--no-title': !isShowProductTitle }"
  >
    <div
      class="product-block__image"
      :class="{
        'image-container': !isNewProductListVersion && !isImagesSlider,
        'image-square': !isNewProductListVersion,
        'image-rectangular': isNewProductListVersion,
      }"
    >
      <HeartIcon
        v-if="product && showWishlistIcon"
        class="product-block__wishlist"
        :class="{ 'product-block__wishlist--active': isProductWishlistActive }"
        @click="saveProductWishlist(product)"
      />
      <template v-if="product">
        <sib-link
          @click="$emit('item-click', product)"
          :to="buildProductHandleToUrl(product.handle)"
          :title="product.title"
          :referer="referer"
          :class="{
            'product-block__image--TM-rectangular':
              isNewProductListVersion && isProductListTM && !mkwcDetailProduct,
            'product-block__image--TM-square':
              isNewProductListVersion && isProductListTM && mkwcDetailProduct,
            'product-block__image--rectangular':
              isNewProductListVersion &&
              !isProductListTM &&
              isRectangularImage(product.listingImage),
            'product-block__image--square':
              isNewProductListVersion &&
              !isProductListTM &&
              !isRectangularImage(product.listingImage),
          }"
        >
          <template v-if="isImagesSlider && productImagesSlider.length > 1">
            <sib-carousel
              :items="(productImagesSlider as any[])"
              class="product-block__image--slider"
            >
              <template #default="{ item, index }">
                <sib-image
                  v-bind="item"
                  :id="null"
                  :url="null"
                  @error="$emit('error-image')"
                  :alt="item.alt || product.title"
                ></sib-image>
                <div
                  class="mkc-detail"
                  v-if="
                    isNewProductListVersion &&
                    index == 0 &&
                    ((!isProductListTM &&
                      !isRectangularImage(product.listingImage)) ||
                      (isProductListTM && mkwcDetailProduct))
                  "
                >
                  {{ mkwcDetailProduct }}
                </div>
              </template>
            </sib-carousel>
          </template>
          <template v-else>
            <sib-image
              :loading="isLazyLoadingImage ? 'lazy' : null"
              v-bind="product.listingImage"
              :id="null"
              :url="null"
              @error="$emit('error-image')"
              :alt="product.listingImage?.alt || product.title"
            ></sib-image>
            <div
              class="mkc-detail"
              v-if="
                isNewProductListVersion &&
                ((!isProductListTM &&
                  !isRectangularImage(product.listingImage)) ||
                  (isProductListTM && mkwcDetailProduct))
              "
            >
              {{ mkwcDetailProduct }}
            </div>
          </template>
        </sib-link>
        <div
          class="product-block__discount-badge"
          v-if="
            finalDisCountApplied?.amountFormated && !isNewProductListVersion
          "
        >
          -{{ finalDisCountApplied?.amountFormated }}
        </div>
        <div
          class="product-block__personalize-badge"
          v-if="isPersonalize && !isNewProductListVersion"
        >
          Personalized
        </div>
      </template>
      <div v-else>
        <sib-skeleton-loading></sib-skeleton-loading>
      </div>
    </div>
    <div class="product-block__info">
      <h3
        :style="isImagesSlider ? 'margin-top: 1.75rem' : ''"
        class="product-block__title"
        v-if="isShowProductTitle"
      >
        <sib-link
          @click="$emit('item-click', product)"
          v-if="product"
          :to="buildProductHandleToUrl(product.handle)"
          :title="product.title"
          :referer="referer"
          >{{ product.title }}
        </sib-link>
        <template v-else>
          <sib-skeleton-loading size="sm" round="sm"></sib-skeleton-loading>
          <sib-skeleton-loading
            size="sm"
            round="sm"
            width="80%"
          ></sib-skeleton-loading>
        </template>
      </h3>

      <div class="product-block__type-and-price">
        <div class="product-block__type">
          <template v-if="product">{{ product.productType }}</template>
          <sib-skeleton-loading
            v-else
            width="30%"
            size="sm"
            round="sm"
          ></sib-skeleton-loading>
        </div>
        <div class="product-block__price">
          <template v-if="product">
            <span
              class="product-block__price-final"
              :class="{
                'product-block__price-filnal--secondary':
                  finalDisCountApplied?.compareAtPrice &&
                  isNewProductListVersion,
              }"
            >
              {{ formatPrice(finalDisCountApplied?.price) }}
            </span>
            <span
              class="product-block__price-compare"
              v-if="finalDisCountApplied?.compareAtPrice"
            >
              {{ formatPrice(finalDisCountApplied?.compareAtPrice) }}
            </span>
          </template>
          <sib-skeleton-loading
            v-else
            width="50%"
            size="sm"
            round="sm"
          ></sib-skeleton-loading>
        </div>
      </div>
      <div class="product-block__custom">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useDiscountStore from '@/store/discount'
import useSettingStore from '@/store/setting'
import { Product, NullOrType } from '@/types'
import { DiscountApplied } from '@/types/discount'
import { buildProductUrl, formatPrice, isProductHasType } from '@/utils/product'
import { computed, ComputedRef } from '@vue/reactivity'
import { isPersonalizeProduct } from '@/utils/product'
import {
  DONATION_PREFIX,
  COLLECTION_COLLECTION_MKWC_META_FILED_KEY,
  PRODUCT_TYPE_GREETING_CARD,
  COLLECTION_TAGS_META_FIELD_NAMESPACE,
  MADEMINE_PRODUCT_HANDLE_PREFIX,
  SIB_SEARCH,
  CACHE_KEY_WISH_LIST_ITEMS,
  ONE_YEAR_IN_MILLISECONDS,
  cloneDeep,
  EVENT_SAVE_PRODUCT_WISH_LIST,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  EVENT_TRACKING_ADD_PRODUCT_TO_WISHLIST,
} from '@/utils'
import HeartIcon from '@/assets/images/icons/icon-heart.svg'
import useCheckoutStore from '@/store/checkout'
import useMediaCampaignStore from '@/store/media'
import useRouteStore from '@/store/route'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import Logger from '@/services/log'
import Analytics from '@/services/analytics'
import { onMounted, ref } from 'vue'
import EventBus from '@/services/eventbus'
import { useRoute } from 'vue-router'

interface ProductProp {
  product?: NullOrType<Product>
  isLazyLoadingImage?: boolean
  isShowProductTitle?: boolean
  isShowProductListOldVersion?: boolean
  isProductListTM?: boolean
  isWishlist?: boolean
  referer?: string
  isImagesSlider?: boolean
  showWishlistIcon?: boolean
}

const route = useRoute()
const mediaCampaignStore = useMediaCampaignStore()
const discountStore = useDiscountStore()
const settingStore = useSettingStore()
const checkoutStore = useCheckoutStore()
const routeStore = useRouteStore()
const props = withDefaults(defineProps<ProductProp>(), {
  isShowProductTitle: true,
  isShowProductListOldVersion: false,
  isProductListTM: false,
  isWishlist: true,
  referer: '',
  isImagesSlider: false,
})

const productWishlist = ref<Product[]>([])

const isProductWishlistActive = computed(() =>
  productWishlist.value.find(
    (product) => product.handle == props.product?.handle
  )
)

const discountApplied: ComputedRef<DiscountApplied | null> = computed(() => {
  if (!props.product) return null
  return discountStore.applyDiscount(
    props.product,
    props.product.price || 0,
    props.product.compareAtPrice || 0,
    (props.product.variants || [])[0]?.sku
  )
})

const isGreetingCardFlow = computed(() => mediaCampaignStore.greetingCardFlow)

const greetingCardDiscountApplied = computed(() => {
  const isProductGreetingCard = isProductHasType(props.product, [
    PRODUCT_TYPE_GREETING_CARD,
  ])
  const isProductTypeGreetingCardInLineItems =
    checkoutStore.localCartLineItems.find((lineItem) =>
      isProductHasType(lineItem?.variant?.product, [PRODUCT_TYPE_GREETING_CARD])
    )

  if (
    isGreetingCardFlow.value &&
    isProductGreetingCard &&
    checkoutStore.localCartLineItems.length &&
    !isProductTypeGreetingCardInLineItems
  ) {
    return {
      amount: 100,
      amountFormated: 100,
      compareAtPrice: props.product?.compareAtPrice || props.product?.price,
      price: 0,
    }
  }
  return
})

const donationDiscountApplied = computed(() => {
  const donationTag = props.product?.tags?.find((tag) =>
    tag.includes(DONATION_PREFIX)
  )
  if (donationTag) {
    return {
      amount: 0,
      amountFormated: 0,
      compareAtPrice: props.product?.compareAtPrice,
      price: props.product?.price,
    }
  }
  return
})

const finalDisCountApplied = computed(() => {
  if (donationDiscountApplied.value) {
    return donationDiscountApplied.value
  } else if (greetingCardDiscountApplied.value) {
    return greetingCardDiscountApplied.value
  }
  return discountApplied.value
})

const isPersonalize = computed(() => {
  return isPersonalizeProduct(
    props.product,
    settingStore.shop?.productTagPersonalize || ''
  )
})

const collectionHandle = computed(() => {
  return route.params.collectionHandle as string
})

const isNewProductListVersion = computed(() => {
  const isNewVersionBaseOnCollectionHandle =
    settingStore.pages?.general?.collectionHandlesNewVersionRatioProductImages?.includes(
      collectionHandle.value
    )
  if (isNewVersionBaseOnCollectionHandle) return true

  const isOldVersionBaseOnCollectionHandle =
    settingStore.pages?.general?.collectionHandlesOldVersionRatioProductImages?.includes(
      collectionHandle.value
    )
  if (isOldVersionBaseOnCollectionHandle) return false

  return (
    !props.isShowProductListOldVersion &&
    settingStore.pages?.general?.collectionRatioProductImagesVersion?.toUpperCase() ==
      'V2'
  )
})

const mkwcDetailProduct = computed(() => {
  const product = props.product
  if (!product) return
  if (props.isProductListTM || (props.isWishlist && product.isProductListTM)) {
    return (
      product?.metafields?.find(
        (elm) =>
          elm?.key == COLLECTION_COLLECTION_MKWC_META_FILED_KEY &&
          elm?.namespace == COLLECTION_TAGS_META_FIELD_NAMESPACE
      )?.value || ''
    )
  } else {
    return product.mkc_detail || ''
  }
})

const productImagesSlider = computed(() => {
  const product = props.product
  if (!product?.images?.length) return []

  let productImages = cloneDeep(product?.images)
  productImages = productImages.map((item) => ({
    src: item.src,
    alt: item.alt,
    width: item.width,
    height: item.height,
    sources: [
      {
        size: 160,
      },
      {
        size: 265,
        media: 361,
      },
      {
        size: 300,
        media: 576,
      },
      {
        size: 265,
        media: 992,
      },
      {
        size: 300,
        media: 1400,
      },
    ],
  }))

  // data's from wishlist cache
  if (props.isWishlist) {
    if (product.isProductListTM) {
      return productImages.length > 1
        ? [productImages[0], productImages[productImages.length - 1]]
        : productImages
    } else {
      return productImages.length > 1
        ? [productImages[productImages.length - 1], productImages[0]]
        : productImages
    }
  }

  if (props.isProductListTM) {
    return productImages.length > 1
      ? [productImages[0], productImages[productImages.length - 1]]
      : productImages
  }

  // data's GP api
  if (import.meta.env.VITE_SEARCH_TYPE == SIB_SEARCH) {
    return cloneDeep(productImages).reverse()
  }

  // data's searchanise api
  return productImages.length > 1
    ? [productImages[productImages.length - 1], productImages[0]]
    : productImages
})
onMounted(async () => {
  productWishlist.value = await getProductWishlist()
})

function isRectangularImage(image?: any) {
  if (!image || !image.width || !image.height) return false
  if (+image.height / +image.width >= 1.3) return true
  return false
}

function buildProductHandleToUrl(productHandle: string) {
  // if product mademine we need ssr to redirect to customize domain
  const isMademineProduct = productHandle.includes(
    MADEMINE_PRODUCT_HANDLE_PREFIX
  )
  if (isMademineProduct) {
    return `${routeStore.currentOrigin}${buildProductUrl(productHandle)}`
  }

  return buildProductUrl(productHandle)
}

async function saveProductWishlist(product: NullOrType<Product>) {
  try {
    if (!product) return
    // add properties isProductListTM to show silder wishlist correct
    product.isProductListTM = props.isProductListTM

    const wishlistItems = await getProductWishlist()
    const index = wishlistItems.findIndex(
      (item) => item.handle === product.handle
    )

    if (index > -1) wishlistItems.splice(index, 1)
    else wishlistItems.unshift(product)

    productWishlist.value = wishlistItems

    EventBus.trigger(EVENT_SAVE_PRODUCT_WISH_LIST)

    await CacheService.instance?.set(
      CACHE_KEY_WISH_LIST_ITEMS,
      cloneDeep(wishlistItems),
      ONE_YEAR_IN_MILLISECONDS
    )

    // Analytics.track(
    //   EVENT_TRACKING_ADD_PRODUCT_TO_WISHLIST,
    //   {
    //     added: index > -1 ? false : true,
    //     productHandle: product.handle,
    //   },
    //   destinationIntegrations([MIXPANEL_DESTINATION])
    // )
  } catch (error: any) {
    Logger.error('Error when saving product wish list to cache', {
      error,
      product,
    })
    Analytics.error(error)
  }
}

async function getProductWishlist() {
  const wishlistItemsFromCache = await CacheService.instance?.get(
    CACHE_KEY_WISH_LIST_ITEMS
  )
  const wishlistItems: Product[] =
    getJSONfromCacheResponse(wishlistItemsFromCache) || []

  return wishlistItems
}
</script>

<style lang="scss">
.product-block {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &__wishlist {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: var(--base-z-index);
    cursor: pointer;

    &--active {
      fill: var(--color-secondary);
    }
  }

  &__image {
    position: relative;
    border: solid 1px var(--border-color);
    line-height: 0;
    .sib-ske-loading {
      width: 100%;
      height: 100%;
    }
  }

  .image-square {
    aspect-ratio: 1;
    .sib-ske-loading {
      aspect-ratio: 1;
    }
    .product-block__image--slider {
      .carousel__viewport,
      .carousel__track,
      .carousel__slide,
      .carousel__slide.is-selected {
        aspect-ratio: 1;
      }

      img {
        aspect-ratio: 1;
        object-fit: contain;
      }
    }
  }

  .image-rectangular {
    aspect-ratio: 0.73533083645;
    .sib-ske-loading {
      aspect-ratio: 0.73533083645;
    }
    .product-block__image--slider {
      .carousel__viewport,
      .carousel__track,
      .carousel__slide,
      .carousel__slide.is-selected {
        aspect-ratio: 0.73533083645;
      }
    }
  }

  &__image--rectangular {
    img {
      object-fit: unset;
      aspect-ratio: 0.73533083645; // width 591px and height 803px
    }
    .product-block__image--slider {
      img {
        aspect-ratio: unset;
      }
    }
  }
  &__image--square {
    img {
      object-fit: contain;
      aspect-ratio: 1;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__image--TM-rectangular {
    img {
      object-fit: unset;
      aspect-ratio: unset;
    }
  }
  &__image--TM-square {
    img {
      object-fit: contain;
      aspect-ratio: 1;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .mkc-detail {
    aspect-ratio: 2.8009478673; // width 591px and height 211px
    font-family: BebasNeueRegular;
    padding: 0 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    font-size: 18px;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }

  .product-block__image--slider {
    margin-bottom: 0 !important;
    .carousel__slide {
      overflow: hidden;
    }
    .carousel {
      &__button.is-prev,
      &__button.is-next {
        display: none;
      }
    }
    &:hover {
      .carousel {
        &__button.is-prev,
        &__button.is-next {
          @include media-md-up {
            display: block;
            border-radius: 50%;
            svg {
              width: 1rem;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .carousel__slide,
    .carousel__slide.is-selected {
      display: grid;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__title {
    margin: var(--space-small) 0 0 0;
    margin-bottom: auto;
    font-size: 14px;
    line-height: 22px;

    a {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 4.5em;
      overflow: hidden;
    }
  }

  &__type-and-price {
    margin-top: 0.5em;
  }

  &__type {
    font-size: 12px;
    color: var(--text-color-tertiary);
  }

  &__price {
    font-size: 18px;
    display: flex;
    gap: 0.5em;
    font-weight: 700;
  }

  &__price-filnal--secondary {
    color: var(--color-secondary);
  }

  &__price-compare {
    text-decoration: line-through;
    color: var(--product-compare-at-price-color);
  }

  &__discount-badge {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    padding: 6px;
    font-size: 11px;
    letter-spacing: -0.5px;
    line-height: 1;
    font-weight: 600;
    background-color: var(--color-secondary);
    color: #fff;
    border-radius: var(--border-radius-small);
    pointer-events: none;
  }

  &__personalize-badge {
    position: absolute;
    --position: 16%;
    top: var(--position);
    left: var(--position);
    transform: translate(-50%, -50%) rotate(-45deg);
    color: #fff;
    background-color: var(--color-primary);
    font-size: 9px;
    font-weight: 700;
    letter-spacing: -0.015em;
    padding: 6px 50px;
    pointer-events: none;
    line-height: 1;
    @media screen and (min-width: 400px) {
      --position: 14%;
    }
    @include media-md-up {
      font-size: 10px;
      --position: 12%;
    }
  }

  $S: &;

  &--no-title {
    #{$S} {
      &__type-and-price {
        display: flex;
        justify-content: space-between;
        margin-top: 1em;
        flex-direction: column;
        gap: 4px;
      }
      &__price {
        font-size: 16px;
        align-self: flex-start;
      }
    }
  }
}
</style>
