import CacheService from '@/services/cache'
import useComponentStore from '@/store/component'
import useDiscountStore from '@/store/discount'
import useSettingStore from '@/store/setting'
import { CampaignDiscount, DiscountApplication } from '@/types/discount'
import {
  DISCOUNT_CODE_DIVIDER,
  DISCOUNT_CONDITION_ALL,
  DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
  CACHE_KEY_CAMPAIGN_DISCOUNT_CODE,
  CACHE_KEY_DISCOUNT_CODE,
  PROMOTION_BAR_PRIORITY_MEDIUM,
  PROMOTION_BAR_TYPE_DISCOUNT,
  PROMOTION_DISCOUNT_MESSAGE_DEFAULT,
  QUERY_STRING_CAMPAIGN_DISCOUNT,
  QUERY_STRING_DISCOUNT,
  SEVEN_DAYS_IN_MILLISECONDS,
  DISCOUNT_FREE_SHIPPING_KEY_PREFIX,
  DISCOUNT_VALUE_TYPE_CART_FREE_SHIPPING,
  QUERY_STRING_GOOGLE_DISCOUNT,
  CACHE_KEY_GOOGLE_DISCOUNT_CODE,
  QUERY_STRING_GOOGLE_CLICK_ID,
  CACHE_KEY_GOOGLE_CLICK_ID,
  QUERY_STRING_TAG_DISCOUNT_CODE,
  CACHE_KEY_TAG_DISCOUNT_CODE,
} from '@/utils'
import { buildDiscountMessage } from '@/utils/discount'
import { isOnServer } from '@/utils/ssr'
import { useRoute } from 'vue-router'

const useDiscount = () => {
  const route = useRoute()
  const discountStore = useDiscountStore()
  const componentStore = useComponentStore()
  const settingStore = useSettingStore()

  const applyDiscount = async () => {
    // only apply discount on the client side
    if (isOnServer) return
    applyProductTagDiscount()

    const ttl = SEVEN_DAYS_IN_MILLISECONDS
    let campaignDiscountCode = route.query[
      QUERY_STRING_CAMPAIGN_DISCOUNT
    ] as string
    let campaign: CampaignDiscount | undefined

    // remove the old discount message
    componentStore.removePromotionBarContent(PROMOTION_BAR_TYPE_DISCOUNT)

    // get campaign discount code from cache first
    const campaginDiscountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_CAMPAIGN_DISCOUNT_CODE)
    )?.value

    // no apply campaign discount code with product skus
    if (campaignDiscountCode || campaginDiscountCodeFromCache) {
      const productSKUsExcludeCampaignDiscount = [
        '28042022601scl1tmm1th06th07my01tsh1fth5030a',
        '28042022601scl1tmm1th06th07my01mug1fth5031a',
        '17052022600scl1tmm1ch02do01an01tsh1stw5501a',
        '16052022604scl1tmm1th06do01an01tsh1stw5500a',
        '12062023903scl1cle1ch02ma01li04tot1ttl6078',
        '13640424001scl1cle1th06ha09th12aos1fth6002',
        '11710524003clo4cle1tr04th07my01clc1grp5134',
        '16550524001scl1cle1th06ha07tr01acm1wed6229',
        '16590524001scl1cle1th06nh02tr01acm1wed6230',
        '11110624001scl1tmr1tr04th07th12hmg1cpl7743',
        '10000624002scl1tmr1tr04th07th12hmg1cpl7734',
      ]

      let isExcludeCampaignDiscount = false
      productSKUsExcludeCampaignDiscount.forEach((item) => {
        if (route.path?.includes(item)) {
          isExcludeCampaignDiscount = true
          if (campaginDiscountCodeFromCache)
            CacheService.instance?.delete(CACHE_KEY_CAMPAIGN_DISCOUNT_CODE)
        }
      })
      if (isExcludeCampaignDiscount) return
    }

    // find the campaign corresponding the campaign discount code
    if (campaignDiscountCode) {
      campaign = settingStore.campaigns?.find(
        (elm) => elm.code.toLowerCase() === campaignDiscountCode.toLowerCase()
      )
    }
    if (!campaign && campaginDiscountCodeFromCache) {
      campaign = settingStore.campaigns?.find(
        (elm) =>
          elm.code.toLowerCase() === campaginDiscountCodeFromCache.toLowerCase()
      )
      campaignDiscountCode = campaginDiscountCodeFromCache
    }
    if (campaign) {
      discountStore.campaignDiscountCode = campaignDiscountCode
      discountStore.campaignDiscount = campaign
      discountStore.discountApplications = campaign.values
      discountStore.discountCode = ''
      if (campaign.promotionMessage) {
        componentStore.addPromotionBarContent({
          type: PROMOTION_BAR_TYPE_DISCOUNT,
          priority: PROMOTION_BAR_PRIORITY_MEDIUM,
          value: campaign.promotionMessage,
        })
      }

      CacheService.instance?.set(
        CACHE_KEY_CAMPAIGN_DISCOUNT_CODE,
        campaignDiscountCode,
        ttl
      )

      CacheService.instance?.delete(CACHE_KEY_GOOGLE_CLICK_ID)
      CacheService.instance?.delete(CACHE_KEY_GOOGLE_DISCOUNT_CODE)
      CacheService.instance?.delete(CACHE_KEY_DISCOUNT_CODE)

      return
    }

    CacheService.instance?.delete(CACHE_KEY_CAMPAIGN_DISCOUNT_CODE)
    discountStore.campaignDiscountCode = ''
    discountStore.campaignDiscount = null
    discountStore.discountApplications = []

    // if do not have campaign. Apply google discount code
    await applyGoogleDiscount()

    //  if do not have campaign. Apply discount code
    const discountCode = route.query[QUERY_STRING_DISCOUNT] as string
    const discountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_DISCOUNT_CODE)
    )?.value
    if (!discountCode && !discountCodeFromCache) return 0

    const incomingDiscountParsed = parsedDiscountCode(discountCode)
    const currentDiscountParsed = parsedDiscountCode(discountCodeFromCache)

    let selectedDiscountParsed = incomingDiscountParsed || currentDiscountParsed

    if (
      incomingDiscountParsed &&
      currentDiscountParsed &&
      incomingDiscountParsed.valueType === currentDiscountParsed.valueType
    ) {
      if (currentDiscountParsed.value > incomingDiscountParsed.value) {
        selectedDiscountParsed = currentDiscountParsed
      }
    }
    if (!selectedDiscountParsed) return

    CacheService.instance?.set(
      CACHE_KEY_DISCOUNT_CODE,
      selectedDiscountParsed.code,
      ttl
    )

    discountStore.discountCode = selectedDiscountParsed.code
    discountStore.discountApplications = [selectedDiscountParsed]
    componentStore.addPromotionBarContent({
      type: PROMOTION_BAR_TYPE_DISCOUNT,
      priority: PROMOTION_BAR_PRIORITY_MEDIUM,
      value: selectedDiscountParsed.message as string,
    })
  }

  const applyGoogleDiscount = async () => {
    const ttl = SEVEN_DAYS_IN_MILLISECONDS

    const googleClidFromQuery = route.query[
      QUERY_STRING_GOOGLE_CLICK_ID
    ] as string
    const googleClidFromCache = (
      await CacheService.instance?.get(CACHE_KEY_GOOGLE_CLICK_ID)
    )?.value

    const googleDiscountCodeFromQuery = route.query[
      QUERY_STRING_GOOGLE_DISCOUNT
    ] as string
    const googleDiscountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_GOOGLE_DISCOUNT_CODE)
    )?.value

    let discountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_DISCOUNT_CODE)
    )?.value

    if (
      googleDiscountCodeFromQuery &&
      googleClidFromQuery &&
      googleClidFromQuery != googleClidFromCache
    ) {
      CacheService.instance?.set(
        CACHE_KEY_GOOGLE_CLICK_ID,
        googleClidFromQuery,
        ttl
      )
      CacheService.instance?.set(
        CACHE_KEY_GOOGLE_DISCOUNT_CODE,
        googleDiscountCodeFromQuery,
        ttl
      )
      CacheService.instance?.delete(CACHE_KEY_DISCOUNT_CODE)
      discountStore.googleDiscountCode = googleDiscountCodeFromQuery
      discountStore.discountCode = ''
    } else if (
      !googleDiscountCodeFromQuery &&
      googleClidFromQuery &&
      googleClidFromQuery != googleClidFromCache
    ) {
      CacheService.instance?.delete(CACHE_KEY_GOOGLE_DISCOUNT_CODE)
      discountStore.googleDiscountCode = ''
      CacheService.instance?.delete(CACHE_KEY_DISCOUNT_CODE)
      discountStore.discountCode = ''
    } else {
      if (googleClidFromCache)
        CacheService.instance?.set(
          CACHE_KEY_GOOGLE_CLICK_ID,
          googleClidFromCache,
          ttl
        )
      if (googleDiscountCodeFromCache) {
        CacheService.instance?.set(
          CACHE_KEY_GOOGLE_DISCOUNT_CODE,
          googleDiscountCodeFromCache,
          ttl
        )
        discountStore.googleDiscountCode = googleDiscountCodeFromCache
      }

      if (discountCodeFromCache) {
        discountStore.discountCode = discountCodeFromCache
      }
    }

    discountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_DISCOUNT_CODE)
    )?.value
    if (googleDiscountCodeFromCache && discountCodeFromCache) {
      discountStore.googleDiscountApplied = true
    }
  }

  const applyProductTagDiscount = async () => {
    const tagDiscountCodeFromQuery = route.query[
      QUERY_STRING_TAG_DISCOUNT_CODE
    ] as string
    const tagDiscountCodeFromCache = (
      await CacheService.instance?.get(CACHE_KEY_TAG_DISCOUNT_CODE)
    )?.value

    const tagDiscountCode = tagDiscountCodeFromQuery || tagDiscountCodeFromCache
    if (tagDiscountCode) {
      CacheService.instance?.set(
        CACHE_KEY_TAG_DISCOUNT_CODE,
        tagDiscountCode,
        SEVEN_DAYS_IN_MILLISECONDS
      )

      const tagDiscountCodeApplication = settingStore.tagDiscountCodes?.find(
        (elm) => elm.code.toLowerCase() === tagDiscountCode.toLowerCase()
      )

      if (tagDiscountCodeApplication) {
        discountStore.tagDiscountCode = tagDiscountCode
        discountStore.tagDiscountCodeApplication = tagDiscountCodeApplication
        discountStore.discountCode = tagDiscountCode
      }
    }
  }

  const parsedDiscountCode = (
    discountCode: string
  ): DiscountApplication | undefined => {
    if (!discountCode) return
    // parse free shipping discount
    if (checkFreeShippingDiscountCode(discountCode)) {
      return {
        code: discountCode,
        conditions: [
          {
            applyFor: DISCOUNT_CONDITION_ALL,
          },
        ],
        value: 0,
        valueType: DISCOUNT_VALUE_TYPE_CART_FREE_SHIPPING,
        message: settingStore.freeShippingMessage,
      }
    }
    // parse value discount
    else if (getValueFromDiscountCode(discountCode) > 0) {
      return {
        code: discountCode,
        conditions: [
          {
            applyFor: DISCOUNT_CONDITION_ALL,
          },
        ],
        value: getValueFromDiscountCode(discountCode),
        valueType: DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
        message: buildDiscountMessage(
          DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE,
          getValueFromDiscountCode(discountCode),
          PROMOTION_DISCOUNT_MESSAGE_DEFAULT
        ),
      }
    }
  }

  return { applyDiscount, parsedDiscountCode, getValueFromDiscountCode }
}

function getValueFromDiscountCode(discountCode: string) {
  if (!discountCode) return 0
  let amount = 0
  // check if the discount code is the pattern XXX10_YYY
  if (discountCode.indexOf(DISCOUNT_CODE_DIVIDER) > -1) {
    const firstPart = discountCode.split(DISCOUNT_CODE_DIVIDER)[0]
    if (firstPart.length >= 2) {
      amount = +firstPart.substring(firstPart.length - 2, firstPart.length)
    }
  } else {
    // get the last two number of the discount code as the discount amount
    amount = +discountCode.substring(
      discountCode.length - 2,
      discountCode.length
    )
  }
  if (isNaN(amount)) return 0
  return amount
}

function checkFreeShippingDiscountCode(discountCode: string) {
  if (!discountCode) return
  return discountCode.startsWith(DISCOUNT_FREE_SHIPPING_KEY_PREFIX)
}

export default useDiscount
