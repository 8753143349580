import {
  MenuComponentState,
  PromotionBarContent,
  PromotionBarState,
} from '@/types'
import { defineStore } from 'pinia'

export interface ComponentsState {
  menu: MenuComponentState
  isShowFooterLink: boolean
  isShowCartPopup: boolean
  homePage: {
    isShowFeaturedCollection: boolean
    isShowBestSelling: boolean
    isShowTopics: boolean
    isShowReviews: boolean
  }
  newHomePage: {
    isShowRecipientCollection: boolean
    isShowPersonalizeYourGift: boolean
    isShowOccasions: boolean
    isShowThemes: boolean
    isShowShopUniqueGiftBanner: boolean
    isShowProductCategories: boolean
    isShowNewAndTrending: boolean
    isShowBestSelling: boolean
    isShowGetToKnowUs: boolean
    isShowShopForGood: boolean
    isShowDesignYourOwn: boolean
    isShowSpecialPerksForSpecialsBanner: boolean
    isShowDoingGoodTogether: boolean
    isShowBrandLogos: boolean
    isShowMoreToExplore: boolean
    isShowKolReviews: boolean
    isShowReviews: boolean
    isShowSubscribeEmailForm: boolean
  }
  productPage: {
    isShowPeopleViewing: boolean
    isShowProductDescription: boolean
    isShowProductShipping: boolean
    isShowSocialSharing: boolean
    isShowProductRecentlyViewedItems: boolean
    isShowProductRecommendation: boolean
    isShowProductReview: boolean
    isShowProductVideo: boolean
  }
  promotionBar: PromotionBarState
}

const useComponentStore = defineStore('component', {
  state: (): ComponentsState => ({
    menu: {
      showingMenus: [],
      activeMenus: [],
    },
    homePage: {
      isShowFeaturedCollection: false,
      isShowBestSelling: false,
      isShowTopics: false,
      isShowReviews: false,
    },
    newHomePage: {
      isShowRecipientCollection: false,
      isShowPersonalizeYourGift: false,
      isShowOccasions: false,
      isShowThemes: false,
      isShowShopUniqueGiftBanner: false,
      isShowProductCategories: false,
      isShowNewAndTrending: false,
      isShowGetToKnowUs: false,
      isShowBestSelling: false,
      isShowShopForGood: false,
      isShowDesignYourOwn: false,
      isShowSpecialPerksForSpecialsBanner: false,
      isShowDoingGoodTogether: false,
      isShowBrandLogos: false,
      isShowMoreToExplore: false,
      isShowKolReviews: false,
      isShowReviews: false,
      isShowSubscribeEmailForm: false,
    },
    productPage: {
      isShowPeopleViewing: false,
      isShowProductDescription: false,
      isShowProductShipping: false,
      isShowSocialSharing: false,
      isShowProductRecentlyViewedItems: false,
      isShowProductRecommendation: false,
      isShowProductReview: false,
      isShowProductVideo: false,
    },
    isShowFooterLink: false,
    isShowCartPopup: false,
    promotionBar: {
      content: [],
      isShow: false,
    },
  }),
  actions: {
    setMenu(menu: MenuComponentState) {
      if (!this.menu) {
        this.menu = menu
        return
      }
      this.menu = Object.assign(this.menu, menu)
    },
    addPromotionBarContent(content: PromotionBarContent) {
      const index = this.promotionBar.content.findIndex(
        (elm) => elm.type === content.type
      )
      if (index > -1) {
        this.promotionBar.content.splice(index, 1, content)
      }

      this.promotionBar.content.unshift(content)
    },
    removePromotionBarContent(type: string) {
      const index = this.promotionBar.content.findIndex(
        (elm) => elm.type === type
      )
      if (index > -1) {
        this.promotionBar.content.splice(index, 1)
      }
    },
  },
})

export default useComponentStore
