import Analytics from '@/services/analytics'
import { UserLocation } from '@/services/location'
import Logger from '@/services/log'
import {
  Collection,
  LocationBlock,
  MetaField,
  NullOrType,
  Product,
  CustomPersonalization,
  ProductVariant,
} from '@/types'
import * as constants from './constants'
import {
  SearchaniseProductVariant,
  ProductRecommendationResult,
} from '@/provider/type'

export function isUILandingProduct(
  product: Product,
  uiLandingProductTag: string
): boolean {
  if (!product || !uiLandingProductTag) return false
  return product.tags?.includes(uiLandingProductTag) || false
}

export function isTMProduct(
  product: Product,
  tmProductTags: string[]
): boolean {
  if (!product || !tmProductTags || !tmProductTags.length) return false
  return (
    product.tags?.some((tag) => tmProductTags.includes(tag.toUpperCase())) ||
    false
  )
}

export function isCollectionHasTag(
  collection?: Collection,
  tag?: string
): boolean {
  if (
    !collection ||
    !collection.metafields ||
    !collection.metafields.length ||
    !tag
  )
    return false
  const tagMetaField = getCollectionTagMetaField(collection.metafields)
  if (!tagMetaField) return false
  let collectionTags: string[] = []
  try {
    collectionTags = JSON.parse(tagMetaField.value)
  } catch (error: any) {
    Logger.error('Collection page', 'Can not convert metafields to tags', error)
    Analytics.error(error)
    return false
  }
  const tagNomalize = tag.toLowerCase()
  const index = collectionTags.findIndex(
    (tag) => tag.toLowerCase() === tagNomalize
  )
  return index > -1
}

export function getCollectionTagMetaField(metafields: Array<MetaField>) {
  return metafields.find(
    (elm) =>
      elm &&
      elm.namespace == constants.COLLECTION_TAGS_META_FIELD_NAMESPACE &&
      elm.key == constants.COLLECTION_TAGS_META_FILED_KEY
  )
}

export function isPersonalizeProduct(
  product: NullOrType<Product>,
  personalizeTagInput: string
): boolean {
  if (!product || !personalizeTagInput) return false
  const personalizeTag = personalizeTagInput.toLowerCase()
  return (
    !!product.tags?.find((tag) => tag.trim().toLowerCase() == personalizeTag) ||
    false
  )
}

export function buildProductUrl(
  productHandleInput: string,
  collectionHandle?: string
) {
  if (productHandleInput.startsWith('/collections/')) return productHandleInput
  const productHandle = productHandleInput.startsWith('/products')
    ? productHandleInput
    : `/products/${productHandleInput}`
  if (collectionHandle && collectionHandle !== 'products') {
    return `/collections/${collectionHandle}${productHandle}`
  }
  return productHandle
}

export function buildCollectionUrl(collectionHandle?: string) {
  if (!collectionHandle) return '/'
  return `/collections/${collectionHandle}`
}

export function formatPrice(price?: NullOrType<number | string>): string {
  if (!price) return '$0'
  if (typeof price === 'string') {
    price = parseFloat(price)
  }
  if (isPriceEqualToZero(price)) return '$0'

  return `$${price.toFixed(2)}`
}

export function isPriceEqualToZero(price: number): boolean {
  // Price that is very small is considerd as zero
  // this will cover some case like 0.1 + 0.2 !== 0.3
  return Math.abs(price) < 0.1
}

// check if option is a size, type or color... option
export function isOptionType(option: string, typeSign: Array<string>) {
  if (!option || !typeSign) return false
  for (let i = 0; i < typeSign.length; i++) {
    if (option.includes(typeSign[i])) return true
  }
  return false
}

export function getOptionType(option: string) {
  if (!option) return constants.OPTION_TYPE_UNKNOW
  const optionStandard = option.toLowerCase()
  if (isOptionType(optionStandard, constants.STYLE_OPTION_SIGN))
    return constants.OPTION_TYPE_STYLE
  if (isOptionType(optionStandard, constants.COLOR_OPTION_SIGN))
    return constants.OPTION_TYPE_COLOR
  if (isOptionType(optionStandard, constants.SIZE_OPTION_SIGN))
    return constants.OPTION_TYPE_SIZE
  return constants.OPTION_TYPE_UNKNOW
}

export function standardizeOptionValue(option: string) {
  if (!option) return option
  return option.toLowerCase().replaceAll(' ', '')
}

export function extractProductSKUFromHandle(href: string) {
  return href.split('/').pop()?.split('-').pop() || ''
}

export function extractProductCodeFromHandle(href: string) {
  const sku = extractProductSKUFromHandle(href)
  return sku.substring(sku.length - 7)
}

export function extractProductNicheFromHandle(href: string) {
  return extractProductCodeFromHandle(href).substring(0, 3)
}

export function extractProductCodeAndNicheCodeFromHandle(href: string) {
  const sku = extractProductSKUFromHandle(href)
  return sku.substring(sku.length - 12)
}

export function getRawIdFromShopifyId(shopifyId?: string) {
  return (shopifyId || '').split('/').pop()?.split('?').shift() || ''
}

export function standardizeCartIdFromShopifyId(shopifyId?: string) {
  return (shopifyId || '').split('/').pop()?.split('?key=').join('___') || ''
}

export function standardizeCheckoutIdFromCartId(cartId: string) {
  const CHECKOUT_ID_PREFIX = 'gid://shopify/Checkout'
  return `${CHECKOUT_ID_PREFIX}/${cartId.split('___').join('?key=')}`
}

export function isProductHasTags(
  product: NullOrType<Product>,
  tags: string[]
): boolean {
  if (!product || !tags || tags.length == 0 || !product.tags?.length)
    return false
  const tagsStandardize = tags.map((tag) => tag.toLowerCase().trim())
  const productTagStandardize = product.tags.map((tag) =>
    tag.toLowerCase().trim()
  )
  for (let i = 0; i < tagsStandardize.length; i++) {
    const tag = tagsStandardize[i]
    if (productTagStandardize.includes(tag)) return true
  }
  return false
}

export function isProductHasTagStartWith(
  product: NullOrType<Product>,
  tag: string
): boolean {
  if (!product || !tag || !product.tags?.length) return false
  const tagStandardize = tag.toLowerCase().trim()
  const productTagStandardize = product.tags.map((tag) =>
    tag.toLowerCase().trim()
  )
  for (let i = 0; i < productTagStandardize.length; i++) {
    const productTag = productTagStandardize[i]
    if (productTag.startsWith(tagStandardize)) return true
  }
  return false
}

export function isProductHasType(
  product: NullOrType<Product>,
  types: string[]
): boolean {
  if (!product?.productType || !types || types.length == 0) return false
  const typesStandardize = types.map((type) => type.toLowerCase().trim())
  const productTypeStandardize = product.productType?.toLowerCase().trim()
  return typesStandardize.includes(productTypeStandardize)
}

export function isProductHasTypeStartWith(
  product: NullOrType<Product>,
  type: string
): boolean {
  if (!product?.productType || !type) return false
  const typeStandardize = type.toLowerCase().trim()
  const productTypeStandardize = product.productType?.toLowerCase().trim()
  return productTypeStandardize.startsWith(typeStandardize)
}

export function isVariantSKUStartWiths(
  variantSKU?: string,
  prefixSKUs?: string[]
) {
  if (!variantSKU || !prefixSKUs) return false
  const standardizeVariantSKU = variantSKU.toLowerCase()
  return prefixSKUs.some((prefixSKU) => {
    const standardizePrefixSKU = prefixSKU.toLocaleLowerCase()
    return standardizeVariantSKU.startsWith(standardizePrefixSKU)
  })
}

// only return the first two part of the SKU
export function getTruncatedVariantSKU(sku?: string) {
  if (!sku) return ''
  const skuParts = sku.split('-')
  if (skuParts.length > 2) {
    skuParts.length = 2
  }
  return skuParts.join('-')
}

// return first part and 7 last characters of the SKU
// if skuFromHandle has last character is the letter, we will get 8 character
export function getTruncatedVariantSKUWithProductHandlle(
  sku?: string,
  handle?: string
) {
  if (!sku) return ''
  let skuParts = sku.split('-')

  // if skuPart index is odd, we get 7 characters
  skuParts = skuParts.map((skuPart, index) =>
    index % 2 == 1 ? skuPart.substring(skuPart.length - 7) : skuPart
  )
  if (handle) {
    const skuFromHandle = extractProductSKUFromHandle(handle)

    // if skuFromHandle has last character is the letter, we will get 8 character
    const characterNumber = isNaN(Number(skuFromHandle.slice(-1))) ? 8 : 7
    return `${skuFromHandle.substring(
      skuFromHandle.length - characterNumber
    )}-${skuParts.join('-')}`
  }
  return skuParts.join('-')
}

export function isRestrictedInLocation(
  key: string,
  locationBlocks?: LocationBlock[],
  location?: UserLocation | null
) {
  if (!key || !locationBlocks || !location) return false
  const locationBlock = locationBlocks.find((elm) => elm.key == key)
  if (!locationBlock) return false
  if (
    location.city &&
    locationBlock.cities?.includes(location.city.toLowerCase())
  )
    return true
  if (
    location.region_code &&
    locationBlock.regions?.includes(location.region_code.toLowerCase())
  )
    return true
  if (
    location.country_code &&
    locationBlock.countries?.includes(location.country_code.toLowerCase())
  )
    return true
  return false
}

export function isProductTagRestrictedInLocation(
  tags: string[],
  locationBlocks?: LocationBlock[],
  location?: UserLocation | null
) {
  if (!tags?.length || !locationBlocks || !location) return false
  const locationBlock = locationBlocks.find((elm) => tags.includes(elm.key))
  if (!locationBlock) return false
  if (
    location.city &&
    locationBlock.cities?.includes(location.city.toLowerCase())
  )
    return true
  if (
    location.region_code &&
    locationBlock.regions?.includes(location.region_code.toLowerCase())
  )
    return true
  if (
    location.country_code &&
    locationBlock.countries?.includes(location.country_code.toLowerCase())
  )
    return true
  return false
}

export function getCustomPersonalization(
  product: NullOrType<Product>,
  customPersonalizations: CustomPersonalization[]
) {
  if (
    !product ||
    !customPersonalizations ||
    customPersonalizations.length == 0 ||
    !product.tags?.length
  )
    return
  const customTagsStandardize = customPersonalizations.map(
    (customPersonalization) => customPersonalization.tag.toLowerCase().trim()
  )
  const productTagStandardize = product.tags.map((tag) =>
    tag.toLowerCase().trim()
  )
  for (let i = 0; i < customTagsStandardize.length; i++) {
    const tag = customTagsStandardize[i]
    if (productTagStandardize.includes(tag))
      return customPersonalizations.find(
        (customPersonalization) => customPersonalization.tag === tag
      )
  }
}

export function mappingSearchaniseVariantToProductVariant(
  searchaniseVariant: SearchaniseProductVariant
): ProductVariant {
  const price = +searchaniseVariant.price || 0
  return {
    price: price,
    priceV2: {
      amount: price,
      currencyCode: constants.CURRENCY,
    },
    sku: searchaniseVariant.sku,
    title: Object.values(searchaniseVariant.options).join(' / '),
    id: `gid://shopify/ProductVariant/${searchaniseVariant.variant_id}`,
  }
}

export function mappingProductRecommendationResultToProduct(
  product: ProductRecommendationResult
) {
  return {
    compareAtPrice: product.compare_at_price,
    handle: product.handle,
    images: [
      {
        src: product.image_src,
        alt: product.title,
        width: product.image_width ? +product.image_width : 1024,
        height: product.image_height ? +product.image_height : 1024,
      },
    ],
    title: product.title,
    price: product.price,
    productType: product.product_type,
    mkc_detail: product.mkwc_detail,
    tags: (product.tags || '').split(', '),
  }
}
