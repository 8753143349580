<template>
  <div
    class="menu"
    :class="{
      'menu--show': isShowing,
    }"
  >
    <div
      class="menu__icon"
      @click="iconClick"
      :class="iconClasses"
      v-if="deep == 0"
    >
      <MenuHamburgerIcon :class="isNewMenuIcon ? 'menu__icon--primary' : ''" />
      <p :class="{ 'opacity-0': isShowing }">{{ header }}</p>
    </div>
    <div class="menu__backdrop" @click="backdropClick" v-if="deep == 0"></div>

    <ul class="menu__content">
      <div class="menu_icon-customer hidden-md-up" @click="login">
        <UserIcon />
        <span>{{ customer?.firstName || 'Login' }}</span>
      </div>
      <li class="menu__header">
        <span>{{ header }}</span>
      </li>
      <li
        class="menu__item"
        :class="{
          'menu__item--active': isActive(deep, index),
        }"
        v-for="(item, index) of items"
        :key="index"
      >
        <sib-link
          class="menu__item-text"
          v-if="!item.childs || !item.childs.length"
          :to="item.url"
          :title="item.title"
          @click="linkClicked($event, item)"
        >
          <span>{{ item.title }}</span>
        </sib-link>
        <template v-else>
          <span class="menu__item-text" @click="showMenu(index,item)">
            <span>{{ item.title }}</span>
            <MenuDownIcon class="menu__down-icon" v-if="deep == 0" />
            <MenuRightIcon class="menu__right-icon" />
          </span>
          <Menu
            class="menu__childs"
            :items="filterSettingsByUserSegment(item.childs)"
            :header="item.title"
            :deep="deep + 1"
            :index="index"
            :parents="[...parents, { deep, index }]"
          >
          </Menu>
        </template>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import useComponentStore from '@/store/component'
import { computed } from '@vue/reactivity'
import MenuHamburgerIcon from '@/assets/images/icons/mobile-menu.svg'
import MenuDownIcon from '@/assets/images/icons/menu-down.svg'
import MenuRightIcon from '@/assets/images/icons/menu-right.svg'
import UserIcon from '@/assets/images/icons/icon-user.svg'
import { useRoute } from 'vue-router'
import { ActiveMenu } from '@/types'
import { onMounted, watch } from 'vue'
import { buildCollectionUrl } from '@/utils/product'
import {
  EVENT_OPEN_POPUP_SIGN_IN,
  EVENT_TRACKING_CLICK_MENU,
  EVENT_TRACKING_OPEN_MENU_MOBILE,
  MIXPANEL_DESTINATION,
  VN_COUNTRY_CODE,
  destinationIntegrations,
} from '@/utils'
import Analytics from '@/services/analytics'
import { useSettings } from '@/composables/settings'
import useCustomerStore from '@/store/customer'
import EventBus from '@/services/eventbus'
import useSettingStore from '@/store/setting'
import useRequestLocationStore from '@/store/requestLocation'

const { filterSettingsByUserSegment } = useSettings()
const settingStore = useSettingStore()
const requestLocation = useRequestLocationStore()

interface MenuItem {
  title: string
  url: string
  childs: MenuItem[]
}

interface Menu {
  items: MenuItem[]
  header?: string
  index?: number
  deep?: number
  parents?: ActiveMenu[]
}

const props = withDefaults(defineProps<Menu>(), {
  items: () => [],
  header: '',
  index: 0,
  deep: 0,
  parents: () => [],
})

defineEmits<{
  (e: 'close'): void
}>()

const route = useRoute()
const collectionHandle = computed(() => {
  const collectionHandle = route.params['collectionHandle']
  if (!collectionHandle) {
    return ''
  }

  return buildCollectionUrl(collectionHandle as string)
})

const customerStore = useCustomerStore()
const componentStore = useComponentStore()
const showingMenus = computed<number[]>(() => {
  return componentStore.menu.showingMenus || []
})
const isShowing = computed<boolean>(() => {
  return showingMenus.value[props.deep] === props.index
})

const iconClasses = computed(() => {
  return {
    'menu__icon--close': showingMenus.value.length == 1,
    'menu__icon--back': showingMenus.value.length > 1,
  }
})

const customer = computed(() => customerStore.customer)

const isNewMenuIcon = computed(
  () =>
    !!(
      requestLocation.countryCode &&
      requestLocation.countryCode.toUpperCase() != VN_COUNTRY_CODE &&
      settingStore.pages?.general?.homePageVersion?.toUpperCase() == 'V2'
    )
)

onMounted(() => {
  watch(
    [() => props.items, collectionHandle],
    () => {
      if (props.deep === 0) {
        componentStore.menu.activeMenus = getActiveMenus(
          collectionHandle.value,
          props.items,
          []
        )
      }
    },
    { immediate: true }
  )
})

function getActiveMenus(
  collectionHandle: string,
  menus: MenuItem[],
  parent: ActiveMenu[]
): ActiveMenu[] | undefined {
  if (!menus.length) return
  const deep = parent.length ? parent[parent.length - 1].deep + 1 : 0

  for (let i = 0; i < menus.length; i++) {
    if (menus[i].url == collectionHandle) {
      return [...parent, { deep, index: i }]
    }
    if (!menus[i].childs) continue
    const activeMenus = getActiveMenus(collectionHandle, menus[i].childs, [
      ...parent,
      { deep, index: i },
    ])
    if (activeMenus) return activeMenus
  }
}

function isActive(deep: number, index: number) {
  if (!componentStore.menu.activeMenus || !collectionHandle.value) return false
  const activeMenuIndex = componentStore.menu.activeMenus.findIndex(
    (elm) => elm.deep === deep && elm.index === index
  )
  if (activeMenuIndex > -1) {
    const parents = componentStore.menu.activeMenus.slice(0, activeMenuIndex)
    if (parents.length === props.parents.length) {
      for (let i = 0; i < parents.length; i++) {
        if (
          parents[i].deep !== props.parents[i].deep ||
          parents[i].index !== props.parents[i].index
        ) {
          return false
        }
      }
      return true
    }
  }
  return false
}

function iconClick() {
  if (showingMenus.value.length == 0) {
    componentStore.setMenu({ showingMenus: [0] })
    // trackingOpenMenuMobile()
  } else {
    const temp = [...showingMenus.value]
    temp.pop()
    componentStore.setMenu({ showingMenus: temp })
  }
}

function showMenu(index: number,item:MenuItem) {
  const temp = [...showingMenus.value]
  temp.push(index)
  componentStore.setMenu({ showingMenus: temp })
  // trackingClickMenu(item)
}

function backdropClick() {
  closeMenu()
}

function closeMenu() {
  componentStore.setMenu({ showingMenus: [] })
}

function linkClicked(event: MouseEvent, item: MenuItem) {
  closeMenu()
  const target = event.target as HTMLElement
  const parent: HTMLElement | null = target.closest('.menu__content')
  
  if (parent) {
    parent.addEventListener('animationend', onAnimationEnd, { once: true })
    requestAnimationFrame(() => {
      parent.classList.add('menu__content--clicked')
    })
  }

  function onAnimationEnd() {
    if (parent) {
      parent.classList.add('menu__content--clicked-end')
      setTimeout(() => {
        parent.style.display = ''
        parent.classList.remove('menu__content--clicked')
        parent.classList.remove('menu__content--clicked-end')
      }, 100)
    }
  }
  // trackingClickMenu(item)
}

function login() {
  EventBus.trigger(EVENT_OPEN_POPUP_SIGN_IN)
}

function trackingOpenMenuMobile() {
  Analytics.track(
    EVENT_TRACKING_OPEN_MENU_MOBILE,{},
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}

function trackingClickMenu(item: MenuItem) {
  const currentPath = route.path
  const targetPath = item.url
  const menuName = item.title
  Analytics.track(
    EVENT_TRACKING_CLICK_MENU,
    {
      path: currentPath,
      target_path: targetPath,
      name: menuName
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}
</script>

<style lang="scss">
.menu {
  --menu-padding: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  &--show {
    > .menu__content {
      transform: translate3d(0, 0, 0);
    }

    .menu__backdrop {
      background-color: rgba($color: #000000, $alpha: 0.5);
      pointer-events: all;
    }
  }

  &__backdrop {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0);
    width: 100vw;
    height: 100vh;
    transition: background-color var(--animation-duration) ease;
    top: 0;
    left: 0;
    pointer-events: none;
    cursor: pointer;
    z-index: var(--popup-z-index);
    @include media-desktop {
      z-index: var(--menu-z-index);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: var(--menu-padding) var(--container-padding);
    padding-top: 5rem;
    list-style: none;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 80vw;
    height: 100vh;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s ease-in-out;
    z-index: var(--popup-z-index);
    @include media-desktop {
      z-index: var(--menu-z-index);
    }
  }

  &__item {
    $S: &;
    display: block;
    padding: 0.6em 0;
    width: 100%;
    cursor: pointer;
    @include media-desktop {
      z-index: var(--menu-z-index);
    }

    &-text {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }

    a {
      font-weight: normal;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }

    &--active {
      > #{$S}-text {
        font-weight: bold;
      }
    }
  }

  &__childs {
    z-index: 1;
  }

  &__header {
    border-bottom: solid 1px var(--border-color);
    font-size: 12px;
    color: var(--text-color-tertiary);
    margin-bottom: 1em;
    padding-bottom: 5px;
  }

  &_icon-customer {
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
    width: 80px;
    height: 35px;
    right: 0;
    top: 8px;
    cursor: pointer;

    span {
      position: absolute;
      font-size: 11px;
      width: 50px;
      font-weight: 600;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      @include media-desktop {
        width: 60px;
      }
    }
  }

  &__icon {
    width: 100%;
    z-index: var(--menu-close-icon-index);
    line-height: 0;
    padding: 10px 10px 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;

    --animation-duration: 0.5s;
    --animation-delay: 0s;
    --icon-size: 18px;

    p {
      color: #b2bec3;
      letter-spacing: -0.36px;
    }

    svg {
      width: var(--icon-size);
      height: var(--icon-size);
    }

    path {
      --animation-timing-function: linear;
      stroke: #000;
      stroke-width: 30;
      fill: none;
      transition: all var(--animation-duration) var(--animation-timing-function)
        var(--animation-delay);
    }

    &--primary {
      path {
        --new-color-primary: #05a871;
        stroke: var(--new-color-primary);
      }
    }

    .path1,
    .path4 {
      --animation-delay: 0s;
      stroke-dasharray: 424 2000;
      stroke-dashoffset: 124;
    }

    .path3,
    .path2 {
      --animation-delay: var(--animation-duration);
      stroke-dasharray: 150 1000;
      stroke-dashoffset: -150;
    }

    &--close {
      .path1 {
        --animation-delay: calc(var(--animation-duration) * 0.7);
        stroke-dashoffset: -600;
      }

      .path4 {
        --animation-delay: calc(var(--animation-duration) * 0.7);
        stroke-dashoffset: -900;
      }

      .path2,
      .path3 {
        --animation-delay: 0s;
        stroke-dashoffset: 150;
      }
    }

    &--back {
      .path1 {
        stroke-dashoffset: -1050;
      }

      .path4 {
        stroke-dashoffset: -1340;
      }

      .path2,
      .path3 {
        --animation-delay: calc(var(--animation-duration));
        stroke-dashoffset: -510;
      }
    }
  }

  &__down-icon {
    display: none;
  }

  @include media-desktop {
    grid-area: d;
    border-top: solid 1px var(--border-color);

    &__down-icon {
      display: inline-block;
    }

    &__right-icon {
      display: none;
    }

    &__item {
      $S: &;
      &--active {
        > #{$S}-text {
          font-weight: normal;
        }
      }
    }

    .menu {
      display: none;
      position: absolute;

      .menu__content {
        flex-direction: column;
        min-width: 250px;
        height: auto;
        border: solid 1px var(--border-color);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.22);
        animation: fadeIn var(--animation-duration) ease-in-out;

        &--clicked-end {
          display: none;
        }

        &--clicked {
          animation: fadeOut var(--animation-duration) ease-in-out;
        }

        .menu__item {
          &-text {
            padding: 0.7em 1em;

            &:hover {
              background-color: var(--color-primary);
              color: #fff;
            }
          }
        }

        .menu {
          left: 100%;
          top: 0;
        }
      }
    }

    .menu__item:nth-last-child(-n + 2) {
      .menu {
        right: 0;

        .menu {
          left: auto;
          right: 100%;
          top: 0;
        }
      }
    }

    &:hover {
      display: block;
    }

    &__icon,
    &__backdrop,
    &__header {
      display: none;
    }

    &__childs {
      border-top: none;
    }

    &__content {
      transform: none;
      position: initial;
      width: 100%;
      height: 100%;
      flex-direction: row;
      padding: 0;
      justify-content: space-between;
    }

    &__item {
      padding: 0;
      position: relative;
      cursor: default;
      width: auto;

      &-text {
        background-color: #fff;
        padding: 0.7em 0;
        white-space: nowrap;
        gap: 4px;
        transition: background-color var(--animation-duration) ease,
          color var(--animation-duration) ease;
      }

      &:hover {
        > .menu {
          display: block;
        }
      }
    }
  }

  .opacity-0 {
    opacity: 0;
  }
}
</style>
